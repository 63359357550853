.tippy-box[data-theme~='tippy-rounded'] {
  background: white;
  border: solid 1px #979797;
  border-radius: 10px;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.5));
  padding: 10px;
  z-index: 900;

  .tippy-arrow {
    color: white;
    border: #707070;
  }
}

.tippy-box[data-theme~='no-padding'] {
  padding: 0;
}
